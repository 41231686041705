import React from "react";
import { Grid, Form, Card, Divider, Input } from "semantic-ui-react";
import {
  Centered,
  CardBasic,
  ButtonBasic,
  ButtonPrimary
} from "@components/Styled";
import { BrowserView, MobileView } from "react-device-detect";
import { Link } from "@reach/router";
import { REQUEST_MAGIC_LINK } from "@gql/Mutations";
import { useMutation } from "@apollo/react-hooks";
import Captcha from "@components/Captcha";

const PasswordResetPage = () => {
  const [isReset, setIsReset] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [captcha, setCaptcha] = React.useState("");
  const [magicLink, { loading }] = useMutation(REQUEST_MAGIC_LINK, {
    onCompleted: () => {
      setIsReset(true);
    }
  });

  const handleCaptcha = value => {
    setCaptcha(value);
  };

  const handleMagicLinkButton = () => {
    magicLink({
      variables: {
        email,
        captcha
      }
    });
  };

  const handleEmailChange = (e, { value }) => {
    setEmail(value);
  };

  return (
    <Grid>
      <Grid.Row style={{ marginTop: "2rem" }}>
        <Grid.Column computer={2} tablet={2} />
        <Grid.Column computer={12} mobile={16} tablet={12}>
          <Centered>
            <CardBasic fluid>
              <Card.Content>
                <Card.Header>
                  <h2>Can't remember your password?</h2>
                </Card.Header>
              </Card.Content>
              {!isReset && (
                <>
                  <Card.Content>
                    <Card.Description>
                      <Form size="big" widths="equal">
                        <Form.Group>
                          <Form.Field>
                            <label>Type your email:</label>
                            <Input
                              placeholder="email@example.com"
                              type="email"
                              onChange={handleEmailChange}
                              style={{ width: "100%" }}
                              required
                            />
                          </Form.Field>
                        </Form.Group>
                      </Form>
                    </Card.Description>
                  </Card.Content>
                  <Card.Content>
                    <Card.Description>
                      <Captcha onSubmit={handleCaptcha} />
                      <BrowserView>
                        <ButtonPrimary
                          onClick={handleMagicLinkButton}
                          size="big"
                          primary
                          loading={loading}
                        >
                          Send Magic link
                        </ButtonPrimary>
                        <ButtonBasic disabled size="big">
                          Request Password Reset
                        </ButtonBasic>
                      </BrowserView>
                      <MobileView>
                        <ButtonPrimary
                          loading={loading}
                          size="big"
                          fluid
                          primary
                          onClick={handleMagicLinkButton}
                        >
                          Send Magic link
                        </ButtonPrimary>
                        <Divider horizontal>or</Divider>
                        <ButtonBasic fluid disabled size="big">
                          Request Password Reset
                        </ButtonBasic>
                      </MobileView>
                    </Card.Description>
                  </Card.Content>
                </>
              )}
              {isReset && (
                <Card.Content>
                  <Card.Description>
                    <p>
                      Done! if the email: <b> {email} </b>exists, then you
                      should check your Inbox!
                    </p>
                  </Card.Description>
                </Card.Content>
              )}
            </CardBasic>
            <Centered>
              <Link to="/">Go back</Link>
            </Centered>
          </Centered>
        </Grid.Column>
        <Grid.Column computer={2} tablet={2} />
      </Grid.Row>
    </Grid>
  );
};

export default PasswordResetPage;
