import React from "react";
import { Button, Segment } from "semantic-ui-react";
import { Link } from "@reach/router";
import withAuth from "@utils/withAuth";
import { LOGOUT, CREATE_ACTION, CREATE_TEST_ACTION } from "@gql/Mutations";
import { useMutation } from "@apollo/react-hooks";
import { Router } from "@reach/router";
import { ME } from "@gql/Queries";
import Layout from "@components/Layout";
import { useAction } from "@utils";
import ListChildren from "./Children/ListChildren";
import AddChildren from './Children/AddChildren';

const DashboardPage = ({ me }) => {
	const [logout, { loading }] = useMutation(LOGOUT);
	const [pushAction] = useAction();
	const dispatchAction = () => {
		console.log(me.id);
		pushAction({
			userId: me.id,
			type: ["NAVIGATION"],
			subtype: ["LOGIN"],
			payload: { test: "test" }
		});
	};

	const handleLogout = () => {
		logout({
			refetchQueries: [
				{
					query: ME
				}
			]
		});
	};

	return (
			<Layout>
				<Segment.Group>
					<Router>
						<ListChildren default me={me} />
						<AddChildren me={me} path="d/add_children"/>
					</Router>
				</Segment.Group>
			</Layout>
	);
};

export default withAuth(DashboardPage);
