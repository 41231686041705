import styled from "styled-components";
import { Button } from "semantic-ui-react";

const primary = "#3fc5f0";
const primaryShadow = "#257694";
const primaryHover = "#40dfff";
const primaryHoverShadow = "#36b7d1";
const primaryActive = "#257694";
const primaryActiveShadow = "#19364a";

const basic = "#ffffff";
const basicShadow = "#e3e3e3";
const basicHover = "#f2f2f2";
const basicHoverShadow = "#f8f8f8";
const basicActive = "#a3a3a3";
const basicActiveShadow = "#737373";

export const ButtonPrimary = styled(Button)`
  background-color: ${primary} !important;
  .ui {
    color: #242424 !important;
  }
  border-radius: 15pt !important;
  font-family: "Baloo Bhai" !important;
  border-width: 2px 2px 4px !important;
  border-bottom: 3px solid ${primaryShadow} !important;
  border-radius: 5px;
  :hover {
    background-color: ${primaryHover} !important;
    border-bottom: 2px solid ${primaryHoverShadow};
  }
  :active {
    background-color: ${primaryActive} !important;
    border-bottom: 2px solid ${primaryActiveShadow} !important;
  }
`;

export const ButtonBasic = styled(Button)`
  background-color: ${basic} !important;
  border-radius: 15pt !important;
  font-family: "Baloo Bhai" !important;
  border-color: ${basicShadow} !important;
  border-bottom: 4px solid ${basicShadow} !important;
  border-left: 2px solid ${basicShadow} !important;
  border-right: 2px solid ${basicShadow} !important;
  border-top: 2px solid ${basicShadow} !important;
  :hover {
    background-color: ${basicHover} !important;
    border-bottom: 2px solid ${basicHoverShadow};
  }
  :active {
    background-color: ${basicActive} !important;
    border-bottom: 2px solid ${basicActiveShadow};
  }
`;

export const Centered = styled("div")`
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
`;
