import React from "react";
import { createGlobalStyle } from "styled-components";
import { Router } from "@reach/router";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { ApolloProvider } from "@apollo/react-hooks";
import { InMemoryCache } from "apollo-cache-inmemory";
import DashboardPage from "@components/Dashboard";
import Login, { Enroll } from "@components/Login";
import PasswordForgot from "@components/PasswordForgot";
import MagicLink from "@components/MagicLink";
import VerifyEmail from "@components/VerifyEmail";
import Debug from "./Debug";
import "semantic-ui-css/semantic.min.css";

//const blue = '#2F2FA2';
//const pink = '#F64C72';
//const purple = '#553D67';
//const gray = '#99738E';
const background = "#fffdf9";

const GlobalStyle = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 15pt;
    min-height: 100vh;
    background-color: ${background};
    background-image:
      linear-gradient(to right, #b8dde6 1px, transparent 1px),
      linear-gradient(to bottom, #b8dde6 1px, transparent 1px);
    background-size: 40px 40px;
  }
  html {
    padding: 0 0 0 0;
    margin: 0 0 0 0;
  }
`;
// declare the uris for the graphql backend endpoint
const uris = {
	local: process.env.REACT_APP_BACKEND_LOCAL,
	master: process.env.REACT_APP_BACKEND_PROD,
	dev: process.env.REACT_APP_BACKEND_DEV
};

//setup the one we are using on current branch
const uri = uris[process.env.REACT_APP_BRANCH || "local"];

//create http link for apollo client
const link = createHttpLink({
	uri: uri,
	credentials: "include"
});

const client = new ApolloClient({
	cache: new InMemoryCache(),
	link
});

function App() {
	return (
		<div className="App">
			<GlobalStyle />
			<ApolloProvider client={client}>
				<Router>
					<DashboardPage default path="/h*" />
					<Debug path="/debug" />
					<Login path="/login" />
					<Enroll path="/enroll" />
					<PasswordForgot path="/password_forgot" />
					<MagicLink path="/magic_link/:token" />
					<VerifyEmail path="/email_confirmation/:verification" />
				</Router>
			</ApolloProvider>
		</div>
	);
}

export default App;
