import React from "react";
import Header from "@components/Header";

const Layout = props => {
  return (
    <div>
      <Header />
      <div style={{ height: "66px" }} />
      {props.children}
    </div>
  );
};

export default Layout;
