import React from "react";
import { Grid, Card, Segment } from "semantic-ui-react";
import { CardBasic } from "@components/Styled";
import { useQuery } from "@apollo/react-hooks";
import { navigate } from "@reach/router";
import { VALIDATE_MAGIC_LINK } from "@gql/Queries";
import Error from "@components/ErrorMessage";

const MagicLinkPage = ({ token }) => {
  const [success, setSuccess] = React.useState("");
  const { data, error, loading } = useQuery(VALIDATE_MAGIC_LINK, {
    variables: {
      token
    },
    onCompleted: () => {
      setSuccess(
        data && data.validateMagicLink && data.validateMagicLink.message
      );
    }
  });
  React.useEffect(() => {
    if (success === "Success!") {
      setTimeout(() => {
        navigate("/");
      }, 500);
    }
  }, [success]);
  if (error)
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column computer={2} mobile={0} tablet={2} />
          <Grid.Column computer={12} mobile={16} tablet={12}>
            <CardBasic fluid>
              <Card.Content>
                <Card.Description>
                  <Error error={error} />
                </Card.Description>
              </Card.Content>
            </CardBasic>
          </Grid.Column>
          <Grid.Column computer={2} mobile={0} tablet={2} />
        </Grid.Row>
      </Grid>
    );
  if (loading) {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column computer={2} mobile={0} tablet={2} />
          <Grid.Column computer={12} mobile={16} tablet={12}>
            <CardBasic fluid>
              <Card.Content>
                <Card.Description>
                  <Segment basic loading>
                    Authenticating....
                  </Segment>
                </Card.Description>
              </Card.Content>
            </CardBasic>
          </Grid.Column>
          <Grid.Column computer={2} mobile={0} tablet={2} />
        </Grid.Row>
      </Grid>
    );
  }
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column computer={2} mobile={0} tablet={2} />
        <Grid.Column computer={12} mobile={16} tablet={12}>
          <CardBasic fluid>
            <Card.Content>
              <Card.Description>
                <Segment basic loading>
                  Redirecting...
                </Segment>
              </Card.Description>
            </Card.Content>
          </CardBasic>
        </Grid.Column>
        <Grid.Column computer={2} mobile={0} tablet={2} />
      </Grid.Row>
    </Grid>
  );
};

export default MagicLinkPage;
