import React from "react";
import { Segment, Message, Icon } from "semantic-ui-react";
import { useQuery } from "@apollo/react-hooks";
import { USER } from "@gql/Queries";
import Error from "@components/ErrorMessage";
import { Link } from "@reach/router";
import ChildrenCard from "./ChildrenCard";
import {CenteredLayout} from "@components/Styled"

const ListChildren = ({ me }) => {
	const { data, error, loading } = useQuery(USER, {
		variables: { userId: me.id }
	});
	if (error) return <Error error={error} />;
	if (loading) return <Segment loading />;
	if (data && data.user.children && data.user.children.length > 0) {
		return (
			<Segment basic>
				<CenteredLayout>
				{data.user.children.map((children, index) => (
					<ChildrenCard
						childrenData={children}
						key={"children" + index}
					/>
				))}
				</CenteredLayout>
			</Segment>
		);
	}
	//if there is no data for children (AKA students) then show this fallback:
	return (
		<Segment>
			<Message warning>
				<Icon name="user" />
				It seems like you hadn't add any students. To do it, please
				<Link to="d/add_children"> Click here</Link>
			</Message>
		</Segment>
	);
};
export default ListChildren;
