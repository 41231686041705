import React from "react";
import { CardBasic } from "@components/Styled";
import { Card, Image, Divider } from "semantic-ui-react";
import styled from "styled-components";

const CardDescriptionContainer = styled("div")`
	display: flex;
	flex-direction: row;
`;
const ImageContainer = styled("div")`
	display: unset;
`;
const ChildInfo = styled("div")`
	display: flex;
	flex-direction: column;
margin-left: 20px;
`;

const ChildrenCard = ({ childrenData }) => {
	const { firstname, lastname } = childrenData;
	return (
		<CardBasic fluid>
			<Card.Content>
				<Card.Description>
					<CardDescriptionContainer>
						<ImageContainer>
							<Image
								circular
								size="tiny"
								src="https://react.semantic-ui.com/images/wireframe/square-image.png"
							/>
						</ImageContainer>
						<ChildInfo>
							<h2>{`${firstname} ${lastname}`}</h2>
							<p>This is some info about the child</p>
						</ChildInfo>
					</CardDescriptionContainer>
				</Card.Description>
			</Card.Content>
		</CardBasic>
	);
};

export default ChildrenCard;
