import React from "react";
import { Grid, Message, Segment, Icon } from "semantic-ui-react";
import { Centered, ButtonBasic } from "@components/Styled";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { RESEND_EMAIL_CONFIRMATION, LOGOUT } from "@gql/Mutations";
import { navigate } from "@reach/router";
import Error from "@components/ErrorMessage";
import { VERIFY_EMAIL, ME } from "@gql/Queries";

const VerifyEmail = ({ verification }) => {
  const [success, setSuccess] = React.useState(false);
  const { data, error, loading } = useQuery(VERIFY_EMAIL, {
    variables: { emailToken: verification },
    onCompleted: () => {
      setSuccess(data && data.verifyEmail && data.verifyEmail.message);
    },
    refetchQueries: [{ query: ME }]
  });

  React.useEffect(() => {
    if (success === "Success!") {
      setTimeout(() => {
        // eslint-disable-next-line no-restricted-globals
        navigate("/");
      }, 500);
    }
  }, [success, verification]);

  if (error) return <Error error={error} />;
  if (loading) return <Segment basic loading />;
  if (data && data.verifyEmail) {
    return (
      <Centered>
        <Segment basic>
          <Message success>
            <Icon name="thumbs up" />
            Success! Redirecting you now!
          </Message>
        </Segment>
      </Centered>
    );
  }
  return null;
};

export const EmailNotVerified = ({ userId }) => {
  const [resendEmailConfirmation, { error, loading }] = useMutation(
    RESEND_EMAIL_CONFIRMATION,
    {
      variables: { id: userId }
    }
  );
  const [logout] = useMutation(LOGOUT, {
    onCompleted: () => {
      navigate("/");
    }
  });
  const [emailSent, setEmailSent] = React.useState(false);

  const handleEmailSend = () => {
    setEmailSent(true);
    resendEmailConfirmation();
  };

  const handleLogout = () => {
    logout({ refetchQueries: [{ query: ME }] });
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column computer={2} tablet={2} />
        <Grid.Column computer={12} mobile={16} tablet={12}>
          <Segment basic padded size="big">
            {!emailSent ? (
              <Message warning>
                <Icon name="warning sign" />
                Please verify your email account.
              </Message>
            ) : (
              <Message success>Success! a new email has ben sent.</Message>
            )}
            {emailSent && <p>Please check your email's inbox!</p>}
            <p>
              If you did not receive an email, we can send you a new
              verification.
            </p>
            <Centered>
              <ButtonBasic
                disabled={emailSent}
                loading={loading}
                onClick={handleEmailSend}
                size="big"
              >
                <Icon name="envelope" /> Send a new verification email.
              </ButtonBasic>
              <ButtonBasic onClick={handleLogout}>
                Send me to login page
              </ButtonBasic>
            </Centered>
            {error && (
              <Centered>
                <Error error={error} />
              </Centered>
            )}
          </Segment>
        </Grid.Column>
        <Grid.Column computer={2} tablet={2} />
      </Grid.Row>
    </Grid>
  );
};

export default VerifyEmail;
