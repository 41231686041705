import React from "react";
import { LoginCardForm } from "./LoginForm";
import { Card, Form, Button, Icon, Grid } from "semantic-ui-react";
import { navigate, Link } from "@reach/router";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { Centered, ButtonPrimary } from "../../Styled";
import { ENROLL } from "../../gql/Mutations";
import { ME } from "../../gql/Queries";

const Enroll = () => {
  const { handleSubmit, register, errors } = useForm();
  const [captchaValue, setCaptchaValue] = React.useState("");
  const [enroll, { loading }] = useMutation(ENROLL, {
    onCompleted: () => {
      navigate("/");
    }
  });

  const onSubmit = data => {
    enroll({
      variables: {
        ...data,
        captcha: captchaValue
      },
      refetchQueries: [
        {
          query: ME
        }
      ]
    });
  };

  const onCaptchaSubmit = value => {
    setCaptchaValue(value);
  };

  return (
    <Grid>
      <Grid.Row style={{ marginTop: "2rem" }}>
        <Grid.Column mobile={1} tablet={4} computer={5} />
        <Grid.Column mobile={14} tablet={8} computer={6}>
          <LoginCardForm fluid>
            <Card.Content>
              <Card.Header>
                <Button
                  floated="right"
                  className="close-modal-button"
                  circular
                  icon="cancel"
                />
                <div className="logo">
                  <Icon name="bolt" />
                  mpower - Enroll!
                </div>
              </Card.Header>
            </Card.Content>
            <Card.Content>
              <Card.Description>
                <Form
                  size="big"
                  widths="equal"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Form.Group>
                    <Form.Field>
                      <label>First name:</label>
                      <input
                        name="firstname"
                        type="text"
                        ref={register({ required: "field is required" })}
                      />
                      {errors.firstname && errors.firstname.message}
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field>
                      <label>Last Name:</label>
                      <input
                        name="lastname"
                        type="text"
                        ref={register({
                          required: "field is required"
                        })}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field>
                      <label>email:</label>
                      <input
                        name="email"
                        ref={register({
                          required: "email is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "invalid email address"
                          }
                        })}
                        type="email"
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field>
                      <label>password:</label>
                      <input
                        name="password"
                        type="password"
                        ref={register({
                          required: "password is required"
                        })}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Field>
                    <Centered>
                      {process.env.REACT_APP_BRANCH && (
                        <ReCAPTCHA
                          sitekey="6LcibtAUAAAAACPODXvTglaJPm_FKsIvyWup4Yjm"
                          onChange={onCaptchaSubmit}
                        />
                      )}
                    </Centered>
                  </Form.Field>
                  <ButtonPrimary size="big" primary fluid loading={loading}>
                    Enroll
                  </ButtonPrimary>
                </Form>
              </Card.Description>
            </Card.Content>
          </LoginCardForm>
          <Centered>
            <Link to="/">Go back</Link>
          </Centered>
        </Grid.Column>
        <Grid.Column mobile={1} tablet={4} computer={5} />
      </Grid.Row>
    </Grid>
  );
};

const Wrapper = props => {
  const { data, error, loading } = useQuery(ME);
  if (error) return <Enroll {...props} />;
  if (loading) return null;
  if (data && data.me) {
    navigate("/");
  }
  return <Enroll {...props} />;
};

export default Wrapper;
