import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Link } from "@reach/router";
import { Segment, Label } from "semantic-ui-react";

const HELLO = gql`
  query HELLO {
    hello {
      message
    }
  }
`;

const Debug = () => {
  const { data, error, loading } = useQuery(HELLO);
  if (error) return <Segment>Error</Segment>;
  if (loading) return <p>Loading...</p>;
  return (
    <Segment.Group>
      <Segment>This is a debug file for our implementation</Segment>
      <Segment>
        This is the debug query: <Label>{data.hello.message}</Label>
      </Segment>
      <Segment>
        <ul>
          <li>
            <Link to="/login">Login page</Link>
          </li>
          <li>
            <Link to="/">Dashboard</Link>
          </li>
        </ul>
      </Segment>
    </Segment.Group>
  );
};

export default Debug;
