import gql from "graphql-tag";

export const CREATE_ACTION = gql`
  mutation CREATE_ACTION(
    $userId: String!
    $type: [String!]
    $subtype: [String!]
    $payload: Json!
  ) {
    createAction(
      userId: $userId
      type: $type
      subtype: $subtype
      payload: $payload
    ) {
      id
    }
  }
`;

export const CREATE_TEST_ACTION = gql`
  mutation CREATE_TEST_ACTION($mensaje: String!) {
    createTestAction(mensaje: $mensaje) {
      message
    }
  }
`;
