import React from "react";
import PropTypes from "prop-types";
import { Message } from "semantic-ui-react";

export const DisplayError = ({ error }) => {
  console.log(error.message);
  if (!error || !error.message) return null;
  if (
    error.networkError &&
    error.networkError.result &&
    error.networkError.result.errors.length
  ) {
    return error.networkError.result.errors.map((error, i) => (
      <Message
        error
        header="Action Forbidden"
        content={error.message.replace("GraphQL error: ", "")}
        data-test="graphql-error"
      />
    ));
  }
  return (
    <Message error header="Action Forbidden" data-test="graphql-error">
      {error.message}
    </Message>
  );
};
DisplayError.defaultProps = {
  error: {}
};

DisplayError.propTypes = {
  error: PropTypes.object
};
export default DisplayError;
