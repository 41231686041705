import gql from "graphql-tag";

export const VALIDATE_MAGIC_LINK = gql`
  query VALIDATE_MAGIC_LINK($token: String!) {
    validateMagicLink(token: $token) {
      message
    }
  }
`;

export const VERIFY_EMAIL = gql`
  query VERIFY_EMAIL($emailToken: String!) {
    verifyEmail(emailToken: $emailToken) {
      message
    }
  }
`;
