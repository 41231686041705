import React from "react";
import { Grid } from "semantic-ui-react";
import { Link } from "@reach/router";
import LoginForm from "./LoginForm";
import { Centered } from "../../Styled";

const LoginModal = () => {
  return (
    <Grid>
      <Grid.Row style={{ marginTop: "2rem" }}>
        <Grid.Column mobile={1} tablet={4} computer={5} />
        <Grid.Column mobile={14} tablet={8} computer={6}>
          <LoginForm />
          <Centered>
            <p>
              Don't have an account? - <Link to="/enroll">Enroll</Link>
            </p>
            <p>
              <Link to="/password_forgot">Can't remember my password</Link>
            </p>
          </Centered>
        </Grid.Column>
        <Grid.Column mobile={1} tablet={4} computer={5} />
      </Grid.Row>
    </Grid>
  );
};

export default LoginModal;
