import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Centered } from "@components/Styled";

const Captcha = ({ onSubmit }) => (
  <Centered>
    {process.env.REACT_APP_BRANCH && (
      <ReCAPTCHA
        sitekey="6LcibtAUAAAAACPODXvTglaJPm_FKsIvyWup4Yjm"
        onChange={onSubmit}
      />
    )}
  </Centered>
);

export default Captcha;
