import { useMutation } from "@apollo/react-hooks";
import { CREATE_ACTION } from "@gql/Mutations";

export const useAction = () => {
  const [createAction, { error, loading }] = useMutation(CREATE_ACTION);

  const pushAction = ({ payload, type, subtype, userId }) => {
    console.log("this is the userId:", userId);
    createAction({
      variables: {
        payload: payload,
        type,
        subtype,
        userId
      }
    });
  };

  return [pushAction];
};

export default useAction;
