import gql from 'graphql-tag'

export const USER = gql`
	query USER($userId: String!){
		user(userId: $userId) {
			id
			email 
			children {
				id 
				firstname
				lastname
			}
		}
	}
`
