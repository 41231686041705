import React from "react";
import { Segment, Form, Divider, Icon } from "semantic-ui-react";
import {
	Centered,
	ButtonPrimary,
	ButtonBasic,
	CenteredLayout
} from "@components/Styled";
import { navigate } from "@reach/router";
import { useMutation } from "@apollo/react-hooks";
import { CREATE_CHILDREN } from "@gql/Mutations";
import { USER } from "@gql/Queries";
import ErrorMessage from "@components/ErrorMessage";

const AddChildrenForm = ({ me }) => {
	const [createChildren, { error, loading }] = useMutation(CREATE_CHILDREN, {
		onCompleted: () => {
			navigate("/d");
		}
	});
	const [formData, setFormData] = React.useState({
		firstname: "",
		lastname: "",
		age: 6
	});

	const handleChange = e => {
		let { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSave = () => {
		const { firstname, lastname, age } = formData;
		createChildren({
			variables: { userId: me.id, firstname, lastname, age },
			refetchQueries: [{ query: USER, variables: { userId: me.id } }]
		});
	};
	return (
		<Segment padded>
			<CenteredLayout>
				<h2>
					<Icon name="user plus" /> Add a new student
				</h2>
				<Divider />
				<Form>
					<Form.Group widths="equal">
						<Form.Field>
							<label htmlFor="name">First name</label>
							<input
								name="firstname"
								onChange={handleChange}
								placeholder="Lilly"
								type="text"
							/>
						</Form.Field>
						<Form.Field>
							<label htmlFor="lastname">Last name</label>
							<input
								name="lastname"
								onChange={handleChange}
								placeholder="Potter"
								type="text"
							/>
						</Form.Field>
						<Form.Field>
							<label htmlFor="age">Age</label>
							<input
								name="age"
								onChange={handleChange}
								value={formData["age"]}
								type="number"
							/>
						</Form.Field>
					</Form.Group>
					<Centered>
						<ButtonPrimary
							loading={loading}
							onClick={handleSave}
							primary
						>
							<Icon name="save" />
							Submit
						</ButtonPrimary>
						<ButtonBasic
							onClick={() => {
								navigate("/");
							}}
						>
							Cancel
						</ButtonBasic>
					</Centered>
				</Form>
				{error && <ErrorMessage error={error} />}
			</CenteredLayout>
		</Segment>
	);
};

export default AddChildrenForm;
