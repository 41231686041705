import React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { LOGIN } from "../../gql/Mutations";
import { ME } from "../../gql/Queries";
import { useMutation } from "@apollo/react-hooks";
import { Card, Form, Button, Divider, Icon } from "semantic-ui-react";
import ReCAPTCHA from "react-google-recaptcha";
import { Centered, ButtonPrimary, ButtonBasic } from "../../Styled";
import Error from "@components/ErrorMessage";
import { useAction } from "@utils";

export const LoginCardForm = styled(Card)`
  border-radius: 12pt !important;
  border-color: #e3e3e3 !important;
  border-width: 2px 2px 2px 2px !important;
  border-style: solid !important;
  label {
    color: #222831 !important;
  }
  .content {
    padding: 15px !important;
  }
  .logo {
    font-family: "Baloo Bhai", cursive;
    color: #222831;
    display: inline-block;
  }
  .close-modal-button {
    display: flex;
    position: relative;
  }
`;

const LoginForm = () => {
  const [captchaValue, setCaptchaValue] = React.useState("");
  const { handleSubmit, register, errors } = useForm();
  const [login, { error, loading }] = useMutation(LOGIN);

  const onSubmit = data => {
    login({
      variables: { ...data, captcha: captchaValue },
      refetchQueries: [{ query: ME }]
    });
  };

  const onCaptchaSubmit = value => {
    setCaptchaValue(value);
  };

  const handleGoogle = e => {
    e.preventDefault();
  };

  return (
    <LoginCardForm fluid>
      <Card.Content>
        <Card.Header>
          <Button
            floated="right"
            className="close-modal-button"
            circular
            icon="cancel"
          />
          <div className="logo">
            <Icon name="bolt" />
            mpower
          </div>
        </Card.Header>
      </Card.Content>
      <Card.Content>
        <Card.Description>
          <Form onSubmit={handleSubmit(onSubmit)} size="big" widths="equal">
            <Form.Group>
              <Form.Field>
                <label>Email: </label>
                <input
                  type="email"
                  ref={register({
                    required: "field is Required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "invalid email address"
                    }
                  })}
                  name="email"
                />
                {errors.email && errors.email.message}
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Pasword: </label>
                <input
                  type="password"
                  name="password"
                  ref={register({ required: "field is required" })}
                />
                {errors.password && errors.password.message}
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <Centered>
                {process.env.REACT_APP_BRANCH && (
                  <ReCAPTCHA
                    sitekey="6LcibtAUAAAAACPODXvTglaJPm_FKsIvyWup4Yjm"
                    onChange={onCaptchaSubmit}
                  />
                )}
              </Centered>
            </Form.Field>
            <ButtonPrimary
              fluid
              className="login-button"
              size="big"
              primary
              type="submit"
            >
              Login
            </ButtonPrimary>
            <Divider />
            <ButtonBasic
              onClick={handleGoogle}
              fluid
              className="login-button-google"
              size="big"
              disabled
              loading={loading}
            >
              <img alt="google logo" src="google.svg" /> Google
            </ButtonBasic>
          </Form>
          {error && <Error error={error} />}
        </Card.Description>
      </Card.Content>
    </LoginCardForm>
  );
};

export default LoginForm;
