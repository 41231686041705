import styled from "styled-components";
import { Button, Card } from "semantic-ui-react";
import React from 'react';
import {Grid} from 'semantic-ui-react';

const primary = "#3fc5f0";
const primaryShadow = "#257694";
const primaryHover = "#40dfff";
const primaryHoverShadow = "#36b7d1";
const primaryActive = "#257694";
const primaryActiveShadow = "#19364a";

const basic = "#ffffff";
const basicShadow = "#e3e3e3";
const basicHover = "#f2f2f2";
const basicHoverShadow = "#f8f8f8";
const basicActive = "#a3a3a3";
const basicActiveShadow = "#737373";

export const CardBasic = styled(Card)`
  border-radius: 12pt !important;
  border-color: #e3e3e3 !important;
  border-width: 2px 2px 2px 2px !important;
  border-style: solid !important;
  label {
    color: #222831 !important;
  }
  .content {
    padding: 15px !important;
  }
  .logo {
    font-family: "Baloo Bhai", cursive;
    color: #222831;
    display: inline-block;
  }
  .close-modal-button {
    display: flex;
    position: relative;
  }
`;

export const ButtonPrimary = styled(Button)`
  background-color: ${primary} !important;
  border-radius: 15pt !important;
  font-family: "Baloo Bhai" !important;
  border-width: 2px 2px 4px !important;
  border-bottom: 3px solid ${primaryShadow} !important;
  border-radius: 5px;
  :hover {
    background-color: ${primaryHover} !important;
    border-bottom: 2px solid ${primaryHoverShadow};
  }
  :active {
    background-color: ${primaryActive} !important;
    border-bottom: 2px solid ${primaryActiveShadow} !important;
  }
`;

export const ButtonBasic = styled(Button)`
  background-color: ${basic} !important;
  border-radius: 15pt !important;
  font-family: "Baloo Bhai" !important;
  border-color: ${basicShadow} !important;
  border-bottom: 4px solid ${basicShadow} !important;
  border-left: 2px solid ${basicShadow} !important;
  border-right: 2px solid ${basicShadow} !important;
  border-top: 2px solid ${basicShadow} !important;
  :hover {
    background-color: ${basicHover} !important;
    border-bottom: 2px solid ${basicHoverShadow};
  }
  :active {
    background-color: ${basicActive} !important;
    border-bottom: 2px solid ${basicActiveShadow};
  }
`;

export const Centered = styled("div")`
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
`;

export const CenteredLayout = props => (
	<Grid>
		<Grid.Row>
			<Grid.Column computer={4} tablet={2} />
			<Grid.Column computer={8} mobile={16} tablet={12}>
				{props.children}
			</Grid.Column>
			<Grid.Column computer={4} tablet={2} />
		</Grid.Row>
	</Grid>
)
