import React from "react";
import { Grid, Button, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { Centered } from "@components/Styled";

const Row = styled(Grid.Row)`
  height: 80px;
  min-height: 80px;
  width: 100%;
  font-family: "Baloo Bhai", cursive;
  font-size: 24pt;
  vertical-align: middle;
  padding-top: 0 !important;
  border-bottom-color: #3fc5f0;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  background-color: rgba(255, 255, 255, 0.7);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  .centered {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    padding: none;
    color: #3fc5f0;
  }
  .side-menu-button {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: auto;
  }
`;

const GridContainer = styled(Grid)`
  margin: 0 0 0 0 !important;
  padding: none;
  height: 80px;
  width: 100%;
  top: 0;
  position: fixed !important;
  z-index: 50;
`;

const Column = styled(Grid.Column)`
  height: 80px;
`;

const Header = () => {
  return (
    <GridContainer>
      <Row>
        <Column
          style={{ paddingTop: "18pt" }}
          only="computer tablet"
          computer={5}
          tablet={5}
          mobile={2}
        >
          <Icon
            onClick={() => {
              // eslint-disable-next-line no-restricted-globals
              location.reload();
            }}
            style={{ color: "#3fc5f0" }}
            name="refresh"
            size="small"
          />
        </Column>
        <Column computer={6} mobile={12} tablet={6}>
          <div style={{ paddingTop: "14pt" }} className="centered">
			  <Centered>kpm</Centered>
          </div>
        </Column>
        <Column
          style={{ paddingTop: "14pt" }}
          computer={5}
          mobile={2}
          tablet={5}
        >
          <Button floated="right" icon="bars" />
        </Column>
      </Row>
    </GridContainer>
  );
};

export default Header;
