import gql from "graphql-tag";

export const LOGIN = gql`
  mutation LOGIN($email: String!, $password: String!, $captcha: String!) {
    login(email: $email, password: $password, captcha: $captcha) {
      id
    }
  }
`;

export const LOGOUT = gql`
  mutation LOGOUT {
    logout {
      message
    }
  }
`;

export const ENROLL = gql`
  mutation ENROLL(
    $email: String!
    $password: String!
    $captcha: String!
    $firstname: String!
    $lastname: String!
  ) {
    enroll(
      email: $email
      password: $password
      captcha: $captcha
      firstname: $firstname
      lastname: $lastname
    ) {
      id
    }
  }
`;

export const REQUEST_MAGIC_LINK = gql`
  mutation REQUEST_MAGIC_LINK($email: String!, $captcha: String!) {
    requestMagicLink(email: $email, captcha: $captcha) {
      message
    }
  }
`;

export const RESEND_EMAIL_CONFIRMATION = gql`
  mutation RESEND_EMAIL_CONFIRMATION($id: String!) {
    resendConfirmation(id: $id) {
      message
    }
  }
`;
