import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { ME } from "../gql/Queries/Me";
import LoginPage from "../components/Login";
import { Segment } from "semantic-ui-react";
import { EmailNotVerified } from "@components/VerifyEmail";

const withAuth = C => {
  return props => {
    const { data, error, loading } = useQuery(ME);
    if (error) return <LoginPage />;
    if (loading) return <Segment style={{ height: "100vh" }} loading />;
    if (data && data.me) {
      if (data.me.emailVerification) {
        return <C {...props} me={data.me} />;
      }
      return <EmailNotVerified userId={data.me.id} />;
    }
    return <LoginPage />;
  };
};

export default withAuth;
